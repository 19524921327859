<script lang="ts" setup>
const runtimeConfig = useRuntimeConfig();
</script>

<template>
  <CdsText
    variant="normal-label"
    color="low-emphasis-on-surface"
  >
    {{ $t('bailiff.header.version') }} {{ runtimeConfig.public.bailiffPanelVersion }}
  </CdsText>
</template>
