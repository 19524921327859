import { useSnackbar } from 'currenda-design-system';
import type { FetchError } from 'ofetch';

import { fetchInstructionFileService } from '~coreServices/bailiff-back-office.service';

import { downloadFileWithRevoke } from '~coreUtils/file';

import { type IMenuListOption } from '~coreTypes/headers-menu.model';

export function useHeaderLinks() {
  const { $createCustomFetchError, $i18n, $routeNames } = useNuxtApp();
  const { create } = useSnackbar();

  async function fetchInstruction() {
    try {
      const response = await fetchInstructionFileService();

      downloadFileWithRevoke(response, { type: 'pdf', name: 'instrukcja.pdf' });
    } catch (error) {
      create({
        message: $i18n.t('header.links.fetchInstructionError'),
        type: 'error'
      });

      $createCustomFetchError(error as FetchError);
    }
  }

  const options = computed<IMenuListOption[]>(() => {
    return [
      {
        text: $i18n.t('header.links.regulation'),
        icon: 'file_paragraph',
        to: {
          name: $routeNames.rules
        }
      },
      {
        text: $i18n.t('header.links.instruction'),
        icon: 'instruction',
        onClick: fetchInstruction,
        target: '_blank'
      },
      {
        text: $i18n.t('header.links.privacyPolicy'),
        icon: 'document',
        to: {
          name: $routeNames.privacyPolicy
        }
      },
      {
        text: $i18n.t('header.links.help'),
        icon: 'to_send',
        to: {
          name: $routeNames.sendReport
        }
      },
      {
        text: $i18n.t('header.links.faq'),
        icon: 'info_circle',
        to: {
          name: $routeNames.bailiff.faqs
        }
      }
    ];
  });

  return {
    options
  };
}
